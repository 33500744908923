<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <page-title text="Componenti" />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <form class="mt-4" @submit.prevent="changePage(1)">
          <v-text-field
            light
            v-model="search"
            prepend-icon="search"
            @click:prepend="getComponenti"
            append-icon="cancel"
            @click:append="resetItems"
            placeholder="Cerca" />
        </form>
        <v-btn v-if="datiFiltrati && componenti.length" @click="stampa()">
          Stampa
        </v-btn>
        <v-btn v-if="datiFiltrati && componenti.length" @click="esporta()">
          Esporta in Excel
        </v-btn>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!componenti.length">
          Nessun componente trovato
        </v-alert>
        <v-list class="mt-2" two-line v-if="componenti.length">
          <template v-for="(componente, index) in componenti">
            <v-list-tile @click.stop="modifica(componente._id)" :key="'tile-'+componente._id">
              <v-list-tile-content>
                <v-list-tile-title v-text="componente.codice+' - '+componente.descrizione" />
                <v-list-tile-sub-title v-text="componente.note" />
              </v-list-tile-content>
              <v-list-tile-action>
                <v-list-tile-action-text class="text--primary" v-if="componente.qta_in > componente.qta_out">
                  Qtà {{componente.qta_in - componente.qta_out}}
                </v-list-tile-action-text>
                <v-list-tile-action-text class="red--text" v-else>
                  Qtà {{componente.qta_in - componente.qta_out}}
                </v-list-tile-action-text>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="componente._id" v-if="index < componenti.length - 1" />
          </template>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
      </v-flex>
      <v-tooltip left>
        <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click.stop="modifica()">
          <v-icon>add</v-icon>
        </v-btn>
        <span>Nuovo componente</span>
      </v-tooltip>
    </v-layout>
    <modal-confirm ref="confirm" />
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import PageTitle from '@/components/common/PageTitle'
import modalConfirm from '@/components/common/ModalConfirm'

export default {
  data: () => ({
    componenti: [],
    search: null,
    datiFiltrati: false,
    pages: 0,
    page: 1,
    paginate: 20
  }),
  components: {
    PageTitle,
    'modal-confirm': modalConfirm
  },
  methods: {
    getComponenti () {
      this.datiFiltrati = !!this.search
      this.$plsqlReadAll('arrivo', {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      })
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.componenti = response.data.items
            this.pages = response.data.pages
          }
        })
        .catch(err => {
          console.log('errore client', err)
          this.$showMessage({ type: 'error', text: 'Errore' })
        })
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.changePage(1)
      }
    },
    changePage (page) {
      this.page = page
      this.getComponenti()
    },
    esporta () {
      this.$plsqlReadAll('arrivo', {
        what: this.search,
        page: 1,
        rows4page: 2000
      })
        .then(response => {
          if (response.data.items.length) {
            response.data.items.forEach((i) => { i.qta = i.qta_in - i.qta_out })
            this.$http({
              method: 'post',
              url: '/excel/arrivi',
              responseType: 'blob',
              data: response.data.items
            }).then((arrivi) => {
              const blob = new Blob([arrivi.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              this.$openFileNewTab(blob, 'componenti-' + this.search + '.xlsx') // con adblocker picche ocio
            }).catch((err) => {
              console.log(err)
              $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare l\'excel degli arrivi' })
            })
          }
        })
        .catch(err => {
          console.log('errore client', err)
          this.$showMessage({ type: 'error', text: 'Errore' })
        })
    },
    stampa () {
      this.$plsqlReadAll('arrivo', {
        what: this.search,
        page: 1,
        rows4page: 2000
      })
        .then(response => {
          if (response.data.items.length) {
            this.$http({
              method: 'post',
              url: '/pdf/arrivi',
              responseType: 'blob',
              data: response.data.items
            }).then((response) => {
              const blob = new Blob([response.data], { type: 'application/pdf' })
              this.$openFileNewTab(blob) // con adblocker picche ocio
            }).catch((err) => {
              console.log(err)
              $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare il pdf' })
            })
          }
        })
        .catch(err => {
          console.log('errore client', err)
          this.$showMessage({ type: 'error', text: 'Errore' })
        })
    },
    modifica (id) {
      this.$router.push({ name: 'componente', params: { id: id || 'add' } })
    }
  },
  mounted () {
    this.getComponenti()
  }
}
</script>
